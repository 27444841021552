import styled from 'styled-components';
import breakpoint from '../../utils/breakpoint';

export const JumboContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 700px;
  position: relative;
  z-index: 1;
`

export const JumboBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const JumboImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  background: ${props => props.backgroundColor ? props.backgroundColor : '#fff'};
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
`

export const JumboDiv = styled.div`
  z-index: 5;
  width: 100%;
  max-width: var(--max-page-width);
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
`

export const JumboContent = styled.div`
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const JumboTitle = styled.h1`
  color: #000;
  font-size: 45px;
  text-align: center;
  font-family: 'DM Sans', 'Noto Kufi Arabic', sans-serif;

  @media screen and (${breakpoint.devices_max.xs}) {
    font-size: 35px;
  }
`

export const JumboSubtitle = styled.p`
  margin-top: 24px;
  color: #000;
  font-size: 30px;
  font-weight: 200;
  text-align: center;

  @media screen and (${breakpoint.devices_max.xs}) {
    font-size: 24px;
  }
`
