import React, { useState, useEffect } from 'react';
import { PageDiv, Container, Row, Column } from '../layouts/Containers';
import { Text } from '../components/Elements/Typography';
import { useTranslation } from 'react-i18next';
import axios from '../utils/axios';
import constants from '../utils/constants';
import { setLoadingDialog } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TH, TR, TD, TBody } from '../components/Elements/TableElements';
import { ZoomImage, Image } from '../components/Elements/Image';
import { useNavigate, useParams } from 'react-router-dom';
import no_photo from '../assets/no_photo.jpg';
import { FlexSpacer } from '../components/Elements/SmallElements';
import { Button, BtnIcon } from '../components/Elements/Button';
import { FaFileDownload } from 'react-icons/fa';
import { openInNewTab } from '../utils/helpers';
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function CarViewer() {

  const [car, setCar] = useState(null);
  const [selectedYardImage, setSelectedYardImage] = useState(null);
  const [selectedAuctionImage, setSelectedAuctionImage] = useState(null);

  const { t } = useTranslation();

  const accessToken = useSelector(state => state.accessToken);
  const isRTL = useSelector(state => state.isRTL);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { cid } = useParams();

  const setPageTitle = () => {
    if (car !== null) {
      document.title = car.year_make_model + ' | ' + t('app_name');
    } else {
      document.title = t('app_name');
    }
  }

  const getCar = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/webapp/cars/' + cid + '/', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setCar(response.data);

      if (response.data.yard_images.length > 0) {
        setSelectedYardImage(response.data.yard_images[0]);
      }

      if (response.data.auction_images.length > 0) {
        setSelectedAuctionImage(response.data.auction_images[0]);
      }
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    getCar();
  }, []);

  useEffect(() => {
    setPageTitle();
  }, [car]);

  return (
    <>
      {
        car !== null ?
        <PageDiv>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
            <Text fontSize="25px" fontWeight="700">
              {t('car_details')}
            </Text>
            <FlexSpacer isRTL={isRTL} />
            {/* <Button color="var(--secondary-color)" dark padding="10px 20px" borderRadius={10}
              onClick={downloadImages}>
              <BtnIcon left={!isRTL} right={isRTL} color="white" iconSize={'26px'}>
                <FaFileDownload />
              </BtnIcon>
              {t('download_images')}
            </Button> */}
          </div>
          <Row style={{marginTop: '20px'}}>
            <Column cols={6} lg={6} md={6} sm={6} xs={12}>
              <div style={{background: '#fff', padding: '20px 10px', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'}}>
                <Text fontSize="22px" fontWeight="500">
                  {t('yard_images')}
                </Text>
                <ZoomImage
                  style={{marginTop: '15px'}}
                  height={450}
                  src={selectedYardImage !== null ? selectedYardImage.image_obj.thumb : no_photo}
                  clickable
                  onClick={() => openInNewTab(selectedYardImage.image_obj.original)}
                />
                <Row style={{marginTop: '10px'}}>
                  {car.yard_images.map((image) => {
                    return (
                      <Column cols={2} lg={2} md={2} sm={3} xs={3} key={'yard_image__' + image.id}>
                        <div style={{cursor: 'pointer'}} onClick={() => setSelectedYardImage(image)}>
                          <Image
                            height='50'
                            src={image.image_obj.thumb}
                          />
                        </div>
                      </Column>
                    );
                  })}
                </Row>
                <Text fontSize="22px" fontWeight="500" style={{marginTop: '25px'}}>
                  {t('auction_images')}
                </Text>
                <ZoomImage
                  style={{marginTop: '15px'}}
                  height={450}
                  src={selectedAuctionImage !== null ? selectedAuctionImage.image_obj.thumb : no_photo}
                  clickable
                  onClick={() => openInNewTab(selectedAuctionImage.image_obj.original)}
                />
                <Row style={{marginTop: '10px'}}>
                  {car.auction_images.map((image) => {
                    return (
                      <Column cols={2} lg={2} md={2} sm={3} xs={3} key={'auction_image__' + image.id}>
                        <div style={{cursor: 'pointer'}} onClick={() => setSelectedAuctionImage(image)}>
                          <Image
                            height='50'
                            src={image.image_obj.thumb}
                          />
                        </div>
                      </Column>
                    );
                  })}
                </Row>
              </div>
            </Column>
            <Column cols={6} lg={6} md={6} sm={6} xs={12}>
              <div style={{padding: '20px 10px'}}>
                <Text fontSize="22px" fontWeight="500">
                  {t('car_info')}
                </Text>
                <Table style={{marginTop: '15px'}}>
                  <TBody>
                    <TR>
                      <TD fontWeight='600'>
                        {t('buying_date')}
                      </TD>
                      <TD>
                        {car.buying_date}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('delivery_date')}
                      </TD>
                      <TD>
                        {car.delivery_date}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('vin')}
                      </TD>
                      <TD>
                        {car.vin}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('year_make_model')}
                      </TD>
                      <TD>
                        {car.year_make_model}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('color')}
                      </TD>
                      <TD>
                        {car.color}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('value')}
                      </TD>
                      <TD>
                        {car.car_value}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('keys')}
                      </TD>
                      <TD>
                        {car.key_info}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('container_number')}
                      </TD>
                      <TD>
                        {car.container_number}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('shipping_price')}
                      </TD>
                      <TD>
                        {car.shipping_price}
                      </TD>
                    </TR>
                    <TR clickable={car.tracking_url !== null}
                      onClick={car.tracking_url !== null ? () => openInNewTab(car.tracking_url) : null}>
                      <TD fontWeight='600'>
                        {t('tracking_url')}
                      </TD>
                      <TD color={'var(--link-color)'}>
                        {car.tracking_url}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('is_shipped')}
                      </TD>
                      <TD color={'var(--primary-color)'} fontSize='23px'>
                        {car.is_shipped === 1 ? <FaCheck /> : <FaXmark />}
                      </TD>
                    </TR>
                  </TBody>
                </Table>
                <Text fontSize="22px" fontWeight="500" style={{marginTop: '10px'}}>
                  {t('files')}
                </Text>
                <Table style={{marginTop: '10px'}}>
                  <TBody>
                    {car.files.map((file) => {
                      return (
                        <TR key={'car_file__' + file.id} clickable onClick={() => openInNewTab(file.file_url)}>
                          <TD color={'var(--link-color)'}>
                            {file.file_name}
                          </TD>
                        </TR>
                      );
                    })}
                  </TBody>
                </Table>
              </div>
            </Column>
          </Row>
        </PageDiv> : <></>
      }
    </>
  )
}

export default CarViewer;
