import React, { useState, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import LoadingDialog from './components/LoadingDialog';
import FullLoading from './components/FullLoading';
import constants from './utils/constants';
import MessageBar from './components/Elements/MessageBar';
import { setLang, setRTL, setAppLoading, setUser, setAccessToken } from './store';
import RedirectTo from './components/RedirectTo';
import axios from './utils/axios';
import { refreshToken } from './utils/helpers';
import { AdminMainContainer, AdminViewContainer } from './layouts/Containers';
import AdminAppNavigation from './layouts/AdminAppNavigation';
import AdminAppBar from './layouts/AdminAppBar';

import UserBar from './layouts/UserBar';

import HomePage from './views/HomePage';
import Login from './views/Login';

// User Views
import Cars from './views/Cars';
import CarViewer from './views/CarViewer';

// Admin Views
import AdminCars from './views/Admin/AdminCars';
import AdminCarViewer from './views/Admin/AdminCarViewer';
import Users from './views/Admin/Users';
import Settings from './views/Admin/Settings';

function App() {

  const isAppLoading = useSelector(state => state.isAppLoading);
  const isFullLoading = useSelector(state => state.isFullLoading);
  const isLoadingDialog = useSelector(state => state.isLoadingDialog);
  const accessToken = useSelector(state => state.accessToken);
  const user = useSelector(state => state.user);
  const isError = useSelector(state => state.isError);
  const errorMessage = useSelector(state => state.errorMessage);
  const isSuccess = useSelector(state => state.isSuccess);
  const successMsg = useSelector(state => state.successMsg);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([constants.cookies.LANG]);
  const [_windowDims, setWindowDims] = useState();

  const [isMobileBarOpen, setMobileBarOpen] = useState(false);

  const handleResize = () => {
    setWindowDims({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  const setAppTitle = () => {
    document.title = t('default_app_title');
  }

  useEffect(() => {
    let lang = cookies[constants.cookies.LANG];
    if (lang === undefined || lang === null) {
      setCookie(constants.cookies.LANG, 'en', { path: '/', domain: constants.COOKIES_DOMAIN_NAME });
      dispatch(setLang('en'));
      lang = 'en';
    } else {
      dispatch(setLang(lang));
    }
    i18n.changeLanguage(lang);
    if (lang === 'ar') {
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
      dispatch(setRTL(true));
    }

    const cookiesAccessToken = cookies[constants.cookies.TOKEN];
    if (cookiesAccessToken !== undefined && cookiesAccessToken !== null) {
      dispatch(setAccessToken(cookiesAccessToken));
      getUserInfo(cookiesAccessToken, lang);
    } else {
      dispatch(setAppLoading(false));
    }

    setAppTitle();
  }, []);

  const getUserInfo = (accessToken, lang) => {
    dispatch(setAppLoading(true));
    axios.get('/auth/user_info/', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'lang': lang,
      }
    }).then((response) => {
      dispatch(setUser(response.data));
      dispatch(setAppLoading(false));
    }).catch((error) => {
      if (error.response.status === constants.status.UNAUTHORIZED) {
        refreshToken(setCookie, removeCookie);
      } else {
        dispatch(setAppLoading(false));
      }
    });
  };

  return (
    <>
      {
        isFullLoading ? <FullLoading /> : <></>
      }
      {
        isLoadingDialog ? <LoadingDialog /> : <></>
      }
      {
        isAppLoading ? <FullLoading /> :
        <Router>
          {
            isError ? <MessageBar message={errorMessage} backgroundColor='var(--danger-color)' /> : <></>
          }
          {
            isSuccess ? <MessageBar message={successMsg} backgroundColor='var(--success-color)' /> : <></>
          }
          {
            accessToken !== null ? (
              <>
                {
                  user.user_type === 'u' ? 
                  <>
                    <UserBar />
                    <Routes>
                      <Route path="/" element={<Cars />} />
                      <Route path="cars/:cid" element={<CarViewer />} />
                      <Route path="*" element={<RedirectTo linkType="router" to="/" redirectType="replace" />} />
                    </Routes>
                  </> : <></>
                }
                {
                  user.user_type === 'a' ? 
                  <>
                    <AdminMainContainer>
                      <AdminAppNavigation isMobileBarOpen={isMobileBarOpen} setMobileBarOpen={setMobileBarOpen} />
                      <AdminViewContainer>
                        <AdminAppBar setMobileBarOpen={setMobileBarOpen} />
                        <Routes>
                          <Route path='/' element={<AdminCars />} />
                          <Route path='cars/:cid' element={<AdminCarViewer />} />
                          <Route path='users' element={<Users />} />
                          <Route path='settings' element={<Settings />} />
                          <Route path="*" element={<RedirectTo linkType="router" to="/" redirectType="replace" />} />
                        </Routes>
                      </AdminViewContainer>
                    </AdminMainContainer>
                  </> : <></>
                }
              </>
            ) : (
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="login" element={<Login />} />
                <Route path="*" element={<RedirectTo linkType="router" to="/" redirectType="replace" />} />
              </Routes>
            )
          }
        </Router>
      }
    </>
  );
}

export default App;
