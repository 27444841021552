import React, { useState, useEffect, useRef } from 'react';
import { Button, BtnIcon, IconButton } from '../Elements/Button';
import { getLangName } from '../../utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { MdLanguage } from 'react-icons/md';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import {
  LangSelectorCard
} from './LanguageSelectorElements';
import {
  List,
  ListItem,
  ListItemContent,
  ListItemTitle,
} from '../Elements/List';
import constants from '../../utils/constants';
import { useCookies } from 'react-cookie';
import axios, { API_KEY_HEADER } from '../../utils/axios';
import { setLoadingDialog } from '../../store';
import breakpoint from '../../utils/breakpoint';


function LanguageSelector({ dark=false }) {

  const [isOpen, setOpen] = useState(false);

  const lang = useSelector(state => state.lang);
  const isRTL = useSelector(state => state.isRTL);
  const accessToken = useSelector(state => state.accessToken);

  const dispatch = useDispatch();

  const ref = useRef();

  const [cookies, setCookie] = useCookies([constants.cookies.LANG]);

  const closeOpenMenu = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  }

  const changeLanguage = (newLang) => {
    setOpen(false);

    if (!constants.SUPPORTED_LANGS.includes(newLang)) {
      return;
    }

    if (lang === newLang) {
      return;
    }

    setCookie(constants.cookies.LANG, newLang, { path: '/', domain: constants.COOKIES_DOMAIN_NAME });
    changeLanguageOnSession(newLang);
  };

  const changeLanguageOnSession = (newLang) => {
    dispatch(setLoadingDialog(true));
    axios.post('/login_session/update/', {access_token: accessToken, lang: newLang}, API_KEY_HEADER)
      .then((response) => {
        dispatch(setLoadingDialog(false));
        window.location.reload();
      })
      .catch((error) => {
        dispatch(setLoadingDialog(false));
        window.location.reload();
      });
  }

  useEffect(() => {
    window.addEventListener('click', closeOpenMenu, { passive: true });

    return () => {
        window.removeEventListener('click', closeOpenMenu);
    };
  }, []);

  return (
    <div style={{position: 'relative'}} ref={ref}>
      {
        breakpoint.checkers.smAndUp() ? 
        <Button color="transparent" dark={dark} padding="5px" onClick={() => setOpen(!isOpen)}>
          <BtnIcon left={!isRTL} right={isRTL} color={dark ? '#fff' : '#000'}>
            <MdLanguage />
          </BtnIcon>
          <span>
            {getLangName(lang)}
          </span>
          <BtnIcon left={isRTL && breakpoint.checkers.smAndUp()} right={!isRTL && breakpoint.checkers.smAndUp()} color={dark ? '#fff' : '#000'}>
            {
              isOpen ? <FiChevronUp /> : <FiChevronDown />
            }
          </BtnIcon>
        </Button> : <IconButton iconSize='27px' onClick={() => setOpen(!isOpen)} color={dark ? '#fff' : '#000'}>
          <MdLanguage />
        </IconButton>
      }
      {
        isOpen ? 
        <LangSelectorCard isRTL={isRTL}>
          <List padding="10px">
            <ListItem clickable borderRadius="10px" onClick={() => changeLanguage('en')}>
              <ListItemContent>
                <ListItemTitle fontWeight="500" fontSize="14px">
                  English
                </ListItemTitle>
              </ListItemContent>
            </ListItem>
            <ListItem clickable borderRadius="10px" onClick={() => changeLanguage('ar')}>
              <ListItemContent>
                <ListItemTitle fontWeight="500" fontSize="14px">
                  العربية
                </ListItemTitle>
              </ListItemContent>
            </ListItem>
          </List>
        </LangSelectorCard> : <></>
      }
    </div>
  );
}

export default LanguageSelector;