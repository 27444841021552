import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../Elements/Dialog';
import { useSelector, useDispatch } from 'react-redux';
import { getBadRequestErrorMessage } from '../../utils/helpers';
import { Button } from '../Elements/Button';
import axios from '../../utils/axios';
import { setLoadingDialog, setSuccess, setError } from '../../store';
import constants from '../../utils/constants';
import { FlexSpacer } from '../Elements/SmallElements';
import PieTextField from '../Elements/TextFields';
import PieCheckbox from '../Elements/Checkbox';

function UserDialog({ isOpen, setOpen, userObj, onSuccess }) {

  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [resetPassword, setResetPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const accessToken = useSelector(state => state.accessToken);
  const isRTL = useSelector(state => state.isRTL);

  const dispatch = useDispatch();

  const clearData = () => {
    setName('');
    setPhoneNumber('');
    setResetPassword(false);
    setPassword('');
    setConfirmPassword('');
  };

  const setDialogOpen = (open) => {
    if (!open) {
      clearData();
    }
    setOpen(open);
  };

  const isFormValid = () => {
    if (userObj === null) {
      return name.length > 0 && phoneNumber.length > 0 && password.length >= 8 && password === confirmPassword;
    } else {
      return name.length > 0 && (!resetPassword || (password.length >= 8 && password === confirmPassword));
    }
  };

  const addUser = () => {
    if (!isFormValid()) {
      return;
    }

    let data = {
      name: name,
      phone_number: phoneNumber,
      password: password,
    };

    dispatch(setLoadingDialog(true));
    axios.post('/admin/users/', data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      dispatch(setSuccess(true, t('general_success')));
      setTimeout(() => {
        dispatch(setSuccess(false, ''));
      }, 3000);
      setDialogOpen(false);
      if (onSuccess !== null) {
        onSuccess();
      }
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.BAD_REQUEST) {
        dispatch(setError(true, t(getBadRequestErrorMessage(error.response.data))));
      } else {
        dispatch(setError(true, t('general_error')));
      }
      setTimeout(() => {
        dispatch(setError(false, ''));
      }, 3000);
    });
  };

  const editUser = () => {
    if (!isFormValid()) {
      return;
    }

    let data = {
      name: name,
    };

    if (resetPassword) {
      data['new_password'] = password;
    }

    dispatch(setLoadingDialog(true));
    axios.put('/admin/users/' + userObj.id + '/', data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      dispatch(setSuccess(true, t('general_success')));
      setTimeout(() => {
        dispatch(setSuccess(false, ''));
      }, 3000);
      setDialogOpen(false);
      if (onSuccess !== null) {
        onSuccess();
      }
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.BAD_REQUEST) {
        dispatch(setError(true, t(getBadRequestErrorMessage(error.response.data))));
      } else {
        dispatch(setError(true, t('general_error')));
      }
      setTimeout(() => {
        dispatch(setError(false, ''));
      }, 3000);
    });
  };

  const onConfirmClick = () => {
    if (userObj === null) {
      addUser();
    } else {
      editUser();
    }
  };

  useEffect(() => {
    if (userObj !== null) {
      setName(userObj.name);
      setResetPassword(false);
      setPassword('');
      setConfirmPassword('');
    } else {
      clearData();
    }
  }, [userObj]);
  
  return (
    <>
      <Dialog
        isOpen={isOpen}
        setOpen={setDialogOpen}
        maxWidth="500px"
        title={userObj === null ? t('add_user') : t('edit_user')}
        padding='10px 15px'
        child={
          <>
            <div>
              <PieTextField
                type="text"
                label={t('name')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {
              userObj === null ?
              <div style={{marginTop: '10px'}}>
                <PieTextField
                  type="text"
                  label={t('phone_number')}
                  labelFontWeight='500'
                  verticalLabel
                  outlined
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div> : <></>
            }
            {
              userObj !== null ?
              <div style={{marginTop: '10px'}}>
                <PieCheckbox
                  title={t('reset_password')}
                  value={resetPassword}
                  onChange={(value) => setResetPassword(value)}
                />
              </div> : <></>
            }
            {
              userObj === null || resetPassword ?
              <>
                <div style={{marginTop: '10px'}}>
                  <PieTextField
                    type="password"
                    label={userObj === null ? t('password') : t('new_password')}
                    labelFontWeight='500'
                    verticalLabel
                    outlined
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div style={{marginTop: '10px'}}>
                  <PieTextField
                    type="password"
                    label={userObj === null ? t('confirm_password') : t('confirm_new_password')}
                    labelFontWeight='500'
                    verticalLabel
                    outlined
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </> : <></>
            }
          </>
        }
        actions={
          <>
            <FlexSpacer isRTL={isRTL} />
            <Button borderRadius={7} padding="10px 15px" dark disabled={!isFormValid()}
              onClick={onConfirmClick}>
              {t('confirm')}
            </Button>
          </>
        }
      />
    </>
  );
}

export default UserDialog;
