import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const UserBarContainer = styled.div`
  background: #fff;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
`

export const UserBarDiv = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  z-index: 1;
  width: 100%;
  padding: 0px 10px;
  max-width: var(--max-page-width);
`

export const BarLogo = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const UserBarEnd = styled.div`
  margin-left: ${props => props.isRTL ? '0' : 'auto'};
  margin-right: ${props => props.isRTL ? 'auto' : '0'};
`
