import React, { useState, useEffect, useRef } from 'react';
import { AdminPageDiv, Container, Row, Column } from '../../layouts/Containers';
import { Text } from '../../components/Elements/Typography';
import { useTranslation } from 'react-i18next';
import axios from '../../utils/axios';
import constants from '../../utils/constants';
import { setLoadingDialog } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TH, TR, TD, TBody } from '../../components/Elements/TableElements';
import { ZoomImage, Image } from '../../components/Elements/Image';
import { useNavigate, useParams } from 'react-router-dom';
import no_photo from '../../assets/no_photo.jpg';
import { FlexSpacer } from '../../components/Elements/SmallElements';
import { Button, BtnIcon, IconButton } from '../../components/Elements/Button';
import { openInNewTab } from '../../utils/helpers';
import { MdImage, MdDelete } from "react-icons/md";
import { MdFileUpload } from "react-icons/md";
import CarDialog from '../../components/DataDialogs/CarDialog';
import { MdEdit } from 'react-icons/md';
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function CarViewer() {

  const [car, setCar] = useState(null);
  const [selectedYardImage, setSelectedYardImage] = useState(null);
  const [selectedAuctionImage, setSelectedAuctionImage] = useState(null);

  const [isCarDialogOpen, setCarDialogOpen] = useState(false);

  const yardImgInput = useRef();
  const auctionImgInput = useRef();
  const fileInput = useRef();

  const { t } = useTranslation();

  const accessToken = useSelector(state => state.accessToken);
  const isRTL = useSelector(state => state.isRTL);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { cid } = useParams();

  const setPageTitle = () => {
    if (car !== null) {
      document.title = car.year_make_model + ' | ' + t('app_name');
    } else {
      document.title = t('app_name');
    }
  }

  const getCar = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/admin/cars/' + cid + '/', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setCar(response.data);

      if (response.data.yard_images.length > 0) {
        setSelectedYardImage(response.data.yard_images[0]);
      }

      if (response.data.auction_images.length > 0) {
        setSelectedAuctionImage(response.data.auction_images[0]);
      }
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    getCar();
  }, []);

  useEffect(() => {
    setPageTitle();
  }, [car]);

  const uploadYardImage = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const files = e.target.files;
    for (const file of files) {
      let data = new FormData();
      data.set('image', file);
      data.set('image_type', 'yard');

      dispatch(setLoadingDialog(true));
      axios.post('/admin/cars/upload_image/' + cid + '/', data, {
        headers: {
          'Authorization': 'Bearer ' + accessToken,
        }
      }).then((response) => {
        dispatch(setLoadingDialog(false));
        getCar();
      }).catch((error) => {
        dispatch(setLoadingDialog(false));
      });
    }
  };

  const uploadAuctionImage = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const files = e.target.files;
    for (const file of files) {
      let data = new FormData();
      data.set('image', file);
      data.set('image_type', 'auction');

      dispatch(setLoadingDialog(true));
      axios.post('/admin/cars/upload_image/' + cid + '/', data, {
        headers: {
          'Authorization': 'Bearer ' + accessToken,
        }
      }).then((response) => {
        dispatch(setLoadingDialog(false));
        getCar();
      }).catch((error) => {
        dispatch(setLoadingDialog(false));
      });
    }
  };

  const uploadFile = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    let file = e.target.files[0];

    let data = new FormData();
    data.set('file', file);

    dispatch(setLoadingDialog(true));
    axios.post('/admin/cars/upload_file/' + cid + '/', data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      getCar();
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
    });
  };

  const deleteYardImage = () => {
    dispatch(setLoadingDialog(true));
    axios.delete('/admin/cars/delete_image/' + selectedYardImage.id + '/', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      setSelectedYardImage(null);
      getCar();
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
    });
  };

  const deleteAuctionImage = () => {
    dispatch(setLoadingDialog(true));
    axios.delete('/admin/cars/delete_image/' + selectedAuctionImage.id + '/', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      setSelectedAuctionImage(null);
      getCar();
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
    });
  };

  const deleteFile = (e, file) => {
    e.stopPropagation();
    dispatch(setLoadingDialog(true));
    axios.delete('/admin/cars/delete_file/' + file.id + '/', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      setSelectedAuctionImage(null);
      getCar();
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
    });
  };

  return (
    <>
      <input 
        type="file"
        ref={yardImgInput}
        style={{display: 'none'}}
        accept="image/jpeg,image/jpg,image/png"
        onChange={uploadYardImage}
        multiple
      />
      <input 
        type="file"
        ref={auctionImgInput}
        style={{display: 'none'}}
        accept="image/jpeg,image/jpg,image/png"
        onChange={uploadAuctionImage}
        multiple
      />
      <input 
        type="file"
        ref={fileInput}
        style={{display: 'none'}}
        accept="*"
        onChange={uploadFile}
      />
      <CarDialog
        isOpen={isCarDialogOpen}
        setOpen={setCarDialogOpen}
        carObj={car}
        onSuccess={getCar}
      />
      {
        car !== null ?
        <AdminPageDiv>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
            <Text fontSize="25px" fontWeight="700">
              {t('car_details')}
            </Text>
            <FlexSpacer isRTL={isRTL} />
            <IconButton iconSize="27px" color="var(--primary-color)"
              onClick={() => setCarDialogOpen(true)}>
              <MdEdit />
            </IconButton>
          </div>
          <Row style={{marginTop: '20px'}}>
            <Column cols={6} lg={6} md={6} sm={6} xs={12}>
              <div style={{background: '#fff', padding: '20px 10px', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Text fontSize="22px" fontWeight="500">
                    {t('yard_images')}
                  </Text>
                  <FlexSpacer isRTL={isRTL} />
                  <IconButton iconSize="29px" color="rgba(0, 0, 0, 0.85)" onClick={() => yardImgInput.current.click()}>
                    <MdImage />
                  </IconButton>
                  {
                    selectedYardImage !== null ?
                    <IconButton iconSize="29px" color="var(--danger-color)"
                      style={{margin: '0px 5px'}}
                      onClick={() => deleteYardImage()}>
                      <MdDelete />
                    </IconButton> : <></>
                  }
                </div>
                <ZoomImage
                  style={{marginTop: '15px'}}
                  height={350}
                  src={selectedYardImage !== null ? selectedYardImage.image_obj.thumb : no_photo}
                />
                <Row style={{marginTop: '10px'}}>
                  {car.yard_images.map((image) => {
                    return (
                      <Column cols={2} lg={2} md={2} sm={3} xs={3} key={'yard_image__' + image.id}>
                        <div style={{cursor: 'pointer'}} onClick={() => setSelectedYardImage(image)}>
                          <Image
                            height='50'
                            src={image.image_obj.thumb}
                          />
                        </div>
                      </Column>
                    );
                  })}
                </Row>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '25px'}}>
                  <Text fontSize="22px" fontWeight="500">
                    {t('auction_images')}
                  </Text>
                  <FlexSpacer isRTL={isRTL} />
                  <IconButton iconSize="29px" color="rgba(0, 0, 0, 0.85)" onClick={() => auctionImgInput.current.click()}>
                    <MdImage />
                  </IconButton>
                  {
                    selectedAuctionImage !== null ?
                    <IconButton iconSize="29px" color="var(--danger-color)"
                      style={{margin: '0px 5px'}}
                      onClick={() => deleteAuctionImage()}>
                      <MdDelete />
                    </IconButton> : <></>
                  }
                </div>
                <ZoomImage
                  style={{marginTop: '15px'}}
                  height={350}
                  src={selectedAuctionImage !== null ? selectedAuctionImage.image_obj.thumb : no_photo}
                />
                <Row style={{marginTop: '10px'}}>
                  {car.auction_images.map((image) => {
                    return (
                      <Column cols={2} lg={2} md={2} sm={3} xs={3} key={'auction_image__' + image.id}>
                        <div style={{cursor: 'pointer'}} onClick={() => setSelectedAuctionImage(image)}>
                          <Image
                            height='50'
                            src={image.image_obj.thumb}
                          />
                        </div>
                      </Column>
                    );
                  })}
                </Row>
              </div>
            </Column>
            <Column cols={6} lg={6} md={6} sm={6} xs={12}>
              <div style={{padding: '20px 10px'}}>
                <Text fontSize="22px" fontWeight="500">
                  {t('car_info')}
                </Text>
                <Table style={{marginTop: '15px'}}>
                  <TBody>
                    <TR>
                      <TD fontWeight='600'>
                        {t('buying_date')}
                      </TD>
                      <TD>
                        {car.buying_date}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('delivery_date')}
                      </TD>
                      <TD>
                        {car.delivery_date}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('vin')}
                      </TD>
                      <TD>
                        {car.vin}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('year_make_model')}
                      </TD>
                      <TD>
                        {car.year_make_model}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('color')}
                      </TD>
                      <TD>
                        {car.color}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('value')}
                      </TD>
                      <TD>
                        {car.car_value}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('keys')}
                      </TD>
                      <TD>
                        {car.key_info}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('container_number')}
                      </TD>
                      <TD>
                        {car.container_number}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('shipping_price')}
                      </TD>
                      <TD>
                        {car.shipping_price}
                      </TD>
                    </TR>
                    <TR clickable={car.tracking_url !== null}
                      onClick={car.tracking_url !== null ? () => openInNewTab(car.tracking_url) : null}>
                      <TD fontWeight='600'>
                        {t('tracking_url')}
                      </TD>
                      <TD color={'var(--link-color)'}>
                        {car.tracking_url}
                      </TD>
                    </TR>
                    <TR>
                      <TD fontWeight='600'>
                        {t('is_shipped')}
                      </TD>
                      <TD color={'var(--primary-color)'} fontSize='23px'>
                        {car.is_shipped === 1 ? <FaCheck /> : <FaXmark />}
                      </TD>
                    </TR>
                  </TBody>
                </Table>
                <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                  <Text fontSize="22px" fontWeight="500">
                    {t('files')}
                  </Text>
                  <FlexSpacer isRTL={isRTL} />
                  <IconButton iconSize="29px" color="rgba(0, 0, 0, 0.85)" onClick={() => fileInput.current.click()}>
                    <MdFileUpload />
                  </IconButton>
                </div>
                <Table style={{marginTop: '10px'}}>
                  <TBody>
                    {car.files.map((file) => {
                      return (
                        <TR key={'car_file__' + file.id} clickable onClick={() => openInNewTab(file.file_url)}>
                          <TD color={'var(--link-color)'}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              {file.file_name}
                              <FlexSpacer isRTL={isRTL} />
                              <IconButton iconSize="23px" color="var(--danger-color)"
                                style={{margin: '0px 5px'}}
                                onClick={(e) => deleteFile(e, file)}>
                                <MdDelete />
                              </IconButton>
                            </div>
                          </TD>
                        </TR>
                      );
                    })}
                  </TBody>
                </Table>
              </div>
            </Column>
          </Row>
        </AdminPageDiv> : <></>
      }
    </>
  )
}

export default CarViewer;
