import React from 'react';
import { TailSpin } from "react-loader-spinner";

function FullLoading() {
  return (
    <div style={{height: '100%', zIndex: '2000', position: 'fixed', 
      width: '100%', background: 'var(--primary-color)', display: 'flex', alignItems: 'center',
      justifyContent: 'center', top: '0', bottom: '0', left: '0', right: '0'}}>
        <TailSpin
          color="#fff"
          height={150}
          width={150}
        />
    </div>
  );
}

export default FullLoading;