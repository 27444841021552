import React, { useState, useEffect } from 'react';
import UserBar from '../layouts/UserBar';
import axios, { API_KEY_HEADER } from '../utils/axios';
import constants from '../utils/constants';
import { setLoadingDialog } from '../store';
import { useDispatch } from 'react-redux';
import HomeJumbo from '../components/HomeJumbo';
import AppFooter from '../layouts/AppFooter';
import no_photo from '../assets/no_photo.jpg';

function HomePage() {

  const [homePageResponse, setHomePageResponse] = useState(null);

  const dispatch = useDispatch();

  const getHomePageJumboImage = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/configs/home_page_jumbo_image/', API_KEY_HEADER).then((response) => {
      setHomePageResponse(response.data);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    getHomePageJumboImage();
  }, []);

  return (
    <>
      <UserBar />
      <HomeJumbo homePageJumboImg={homePageResponse !== null && homePageResponse.home_page_jumbo_image !== null ? homePageResponse.home_page_jumbo_image : no_photo} />
      <AppFooter />
    </>
  );
}

export default HomePage;
