import React, { useState } from 'react';
import {
  JumboContainer,
  JumboBg,
  JumboImg,
  JumboDiv,
  JumboContent,
  JumboTitle,
  JumboSubtitle
} from './HomeJumboElements';
//import { useTranslation } from 'react-i18next';

function HomeJumbo({ homePageJumboImg }) {

  //const { t } = useTranslation();

  return (
    <>
      <JumboContainer>
        <JumboBg>
          <JumboImg
            src={homePageJumboImg}
            alt={'RoboaAlqalaa'}
            backgroundColor='var(--primary-color)'
          />
        </JumboBg>
        <JumboDiv>
          {/* <JumboContent>
            <JumboTitle>
              {t('home_jumbo_title')}
            </JumboTitle>
            <JumboSubtitle>
              {t('home_jumbo_subtitle')}
            </JumboSubtitle>
          </JumboContent> */}
        </JumboDiv>
      </JumboContainer>
    </>
  );
}

export default HomeJumbo;
