const en = {
  default_app_title: 'Roboa AlQalaa',
  app_name: 'Roboa AlQalaa',
  login_title: 'Login To Your Account',
  phone_number: 'Phone Number',
  password: 'Password',
  login_btn: 'Login',
  my_account_settings: 'My Account Settings',
  my_cars: 'My Cars',
  image: 'Image',
  date: 'Date',
  delivery_date: 'Delivery Date',
  year_make_model: 'Year/Make/Model',
  vin: 'VIN',
  color: 'Color',
  keys: 'Keys',
  branch: 'Branch',
  logout: 'Logout',
  car_details: 'Car Details',
  download_images: 'Download Images',
  yard_images: 'Yard Images',
  auction_images: 'Auction Images',
  car_info: 'Car Info',
  value: 'Value',
  container_number: 'Container Number',
  files: 'Files',
  cars: 'Cars',
  users: 'Users',
  add_car: 'Add a Car',
  edit_car: 'Edit Car',
  delete_car: 'Delete Car',
  confirm_delete_car: 'Are you sure you want to delete this car?',
  user: 'User',
  add_user: 'Add a User',
  edit_user: 'Edit User',
  confirm: 'Confirm',
  yes: 'Yes',
  no: 'No',
  general_success: 'Action Executed Successfully!',
  general_error: 'Something wrong happened, please try again.',
  unable_to_login: 'Unable to login with the provided phone number and password.',
  actions: 'Actions',
  name: 'Name',
  confirm_password: 'Confirm Password',
  reset_password: 'Reset Password',
  new_password: 'New Password',
  confirm_new_password: 'Confirm New Password',
  home_jumbo_title: 'Roboa Alqalaa Company For Car Shipping',
  home_jumbo_subtitle: 'Following up on the car’s affairs from the moment of purchase until it is shipped to the free zone',
  contact: 'Contact',
  links: 'Links',
  facebook: 'Facebook',
  buying_date: 'Buying Date',
  set_delivery_date: 'Set Delivery Date',
  home_page_jumbo_image: 'Home Page Image',
  settings: 'Settings',
  search_placeholder: 'Search',
  shipping_price: 'Shipping Price',
  tracking_url: 'Tracking Url',
  is_shipped: 'Car Shipped',
};

export default en;
