import React, { useState, useEffect } from 'react';
import { PageDiv, Container } from '../layouts/Containers';
import { Text } from '../components/Elements/Typography';
import { useTranslation } from 'react-i18next';
import axios from '../utils/axios';
import constants from '../utils/constants';
import { setLoadingDialog } from '../store';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TH, TR, TD, TBody } from '../components/Elements/TableElements';
import { Image } from '../components/Elements/Image';
import { useNavigate } from 'react-router-dom';
import no_photo from '../assets/no_photo.jpg';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Pagination } from '@zendeskgarden/react-pagination';
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function Cars() {

  const [carsResponse, setCarsResponse] = useState(null);
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const accessToken = useSelector(state => state.accessToken);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const setPageTitle = () => {
    document.title = t('my_cars') + ' | ' + t('app_name');
  }

  const getCars = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/webapp/cars/?page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setCarsResponse(response.data);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    setPageTitle();
  }, []);

  useEffect(() => {
    getCars();
  }, [page]);

  const onPageClick = (value) => {
    setPage(value);
    window.scroll(0, 0);
  };

  return (
    <>
      <PageDiv>
        <Container fluid>
          <Text fontSize="25px" fontWeight="700" style={{marginTop: '20px'}}>
            {t('my_cars')}
          </Text>
          {
            carsResponse !== null && carsResponse.data.length > 0 ? <>
              <Table style={{marginTop: '20px'}}>
                <thead>
                  <TH>
                    {t('image')}
                  </TH>
                  <TH>
                    {t('date')}
                  </TH>
                  <TH>
                    {t('year_make_model')}
                  </TH>
                  <TH>
                    {t('vin')}
                  </TH>
                  <TH>
                    {t('color')}
                  </TH>
                  <TH>
                    {t('keys')}
                  </TH>
                  <TH>
                    {t('branch')}
                  </TH>
                  <TH>
                    {t('is_shipped')}
                  </TH>
                </thead>
                <TBody>
                  {
                    carsResponse.data.map((car) => {
                      return (
                        <TR key={'car__' + car.id} clickable onClick={() => navigate('/cars/' + car.id)}>
                          <TD>
                            <Image
                              src={car.first_image !== null ? car.first_image.thumb : no_photo}
                              height='110px'
                            />
                          </TD>
                          <TD>
                            {car.buying_date}
                          </TD>
                          <TD>
                            {car.year_make_model}
                          </TD>
                          <TD>
                            {car.vin}
                          </TD>
                          <TD>
                            {car.color}
                          </TD>
                          <TD>
                            {car.key_info}
                          </TD>
                          <TD>
                            {car.branch}
                          </TD>
                          <TD fontSize='24px' color='var(--primary-color)'>
                            {car.is_shipped === 1 ? <FaCheck /> : <FaXmark />}
                          </TD>
                        </TR>
                      );
                    })
                  }
                </TBody>
              </Table>
            </> : <></>
          }
          {
            carsResponse !== null ?
            <div style={{marginTop: '10px'}}>
              {
                carsResponse.last_page > 1 ?
                <div style={{marginTop: '10px'}}>
                  <ThemeProvider>
                    <Pagination
                      totalPages={carsResponse.last_page}
                      currentPage={page}
                      onChange={onPageClick}
                    />
                  </ThemeProvider>
                </div> : <></>
              }
            </div> : <></>
          }
        </Container>
      </PageDiv>
    </>
  )
}

export default Cars;
