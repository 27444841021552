import styled from "styled-components";

export const UserMenuCard = styled.div`
  position: absolute;
  padding: 0;
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 20px;
  width: 300px;
  right: ${props => props.isRTL ? 'unset' : '0'};
  left: ${props => props.isRTL ? '0' : 'unset'};
  z-index: 15;
`