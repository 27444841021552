const ar = {
  default_app_title: 'ربوع القلعة',
  app_name: 'ربوع القلعة',
  login_title: 'سجّل الدخول إلى حسابك',
  phone_number: 'رقم الهاتف',
  password: 'كلمة السر',
  login_btn: 'تسجيل الدخول',
  my_account_settings: 'اعدادات حسابي',
  my_cars: 'سياراتي',
  image: 'الصورة',
  date: 'التاريخ',
  delivery_date: 'تاريخ الوصول',
  year_make_model: 'السنة/النوع/الموديل',
  vin: 'VIN',
  color: 'اللون',
  keys: 'المفاتيح',
  branch: 'الفرع',
  logout: 'تسجيل الخروج',
  car_details: 'معلومات السيارة',
  download_images: 'تحميل الصور',
  yard_images: 'صور الساحة',
  auction_images: 'صور المزاد',
  car_info: 'بيانات السيارة',
  value: 'القيمة',
  container_number: 'رقم ال Container',
  files: 'الملفات',
  cars: 'السيارات',
  users: 'المستخدمين',
  add_car: 'اضافة سيارة',
  edit_car: 'تعديل السيارة',
  delete_car: 'حذف السيارة',
  confirm_delete_car: 'هل أنت متأكد أنك تريد حذف هذه السيارة؟',
  user: 'المستخدم',
  add_user: 'اضافة مستخدم',
  edit_user: 'تعديل المستخدم',
  confirm: 'تأكيد',
  yes: 'نعم',
  no: 'لا',
  general_success: 'تم تنفيذ العملية بنجاح!',
  general_error: 'حدث خطأ، الرجاء المحاولة مرة أخرى.',
  unable_to_login: 'رقم الهاتف وكلمة السر الذين قمت بإدخالهما غير صحيحين',
  actions: 'الإجراءات',
  name: 'الإسم',
  confirm_password: 'تأكيد كلمة السر',
  reset_password: 'تغيير كلمة السر',
  new_password: 'كلمة السر الجديدة',
  confirm_new_password: 'تأكيد كلمة السر الجديدة',
  home_jumbo_title: 'شركة ربوع القلعة لشحن وتجارة السيارات',
  home_jumbo_subtitle: 'متابعة امور السيارة من لحظة الشراء الى حين شحنها للمنطقة الحرة',
  contact: 'تواصل معنا',
  links: 'الروابط',
  facebook: 'الفيسبوك',
  buying_date: 'تاريخ الشراء',
  set_delivery_date: 'تحديد تاريخ الوصول',
  home_page_jumbo_image: 'صورة الشاشة الرئيسية',
  settings: 'الإعدادات',
  search_placeholder: 'البحث',
  shipping_price: 'سعر الشحن',
  tracking_url: 'رابط التتبع',
  is_shipped: 'السيارة واصلة',
};

export default ar;
