import React, { useState, useEffect, useRef } from 'react';
import {
  UserMenuCard
} from './UserMenuElements';
import { AvatarButton, AvatarBtnText } from '../Elements/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar, AvatarImg } from '../Elements/SmallElements';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
} from '../Elements/List';
import { Divider } from '../Elements/SmallElements';
import { FiLogOut } from 'react-icons/fi';
import { logoutUser } from '../../utils/helpers';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';


function UserMenu() {

  const [isOpen, setOpen] = useState(false);

  const isRTL = useSelector(state => state.isRTL);
  const user = useSelector(state => state.user);
  const accessToken = useSelector(state => state.accessToken);

  const ref = useRef();

  const dispatch = useDispatch();

  const [cookies, setCookie, removeCookie] = useCookies();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const closeOpenMenu = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', closeOpenMenu, { passive: true });

    return () => {
        window.removeEventListener('click', closeOpenMenu);
    };
  }, []);

  // const goToAccount = () => {
  //   navigate('/account');
  //   setOpen(false);
  // };

  return (
    <div style={{position: 'relative'}} ref={ref}>
      <AvatarButton size="50px" onClick={() => setOpen(!isOpen)}>
        {
          user.image === null ? 
          <AvatarBtnText color="#fff" fontSize="20px" fontWeight="500">
            {user.name.substring(0, 1)}
          </AvatarBtnText> :
          <AvatarImg src={user.image_url} />
        }
      </AvatarButton>
      {
        isOpen ? 
        <UserMenuCard isRTL={isRTL}>
          <ListItem style={{margin: '10px 3px 5px'}} borderRadius="10px">
            <Avatar size={65}>
              {
                user.image === null ? user.name.substring(0, 1) : <AvatarImg src={user.image.thumb_sm} />
              }
            </Avatar>
            <ListItemContent>
              <ListItemTitle fontSize="15px">
                {user.name}
              </ListItemTitle>
              {/* <ListItemSubtitle fontSize="14px">
                {t('my_account_settings')}
              </ListItemSubtitle> */}
            </ListItemContent>
          </ListItem>
          <Divider />
          <List style={{padding: '10px'}}>
            <ListItem clickable borderRadius="10px" onClick={() => logoutUser(accessToken, dispatch, removeCookie)}>
              <ListItemIcon iconSize="18px">
                <FiLogOut />
              </ListItemIcon>
              <ListItemContent>
                <ListItemTitle fontWeight="400" fontSize="13px">
                  {t('logout')}
                </ListItemTitle>
              </ListItemContent>
            </ListItem>
          </List>
        </UserMenuCard> : <></>
      }
    </div>
  );
}

export default UserMenu;