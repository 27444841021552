import React, { useState, useEffect, useCallback } from 'react';
import { AdminPageDiv, Container } from '../../layouts/Containers';
import { Text } from '../../components/Elements/Typography';
import { useTranslation } from 'react-i18next';
import axios from '../../utils/axios';
import constants from '../../utils/constants';
import { setLoadingDialog } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TH, TR, TD, TBody } from '../../components/Elements/TableElements';
import { Image } from '../../components/Elements/Image';
import { useNavigate, useLocation } from 'react-router-dom';
import no_photo from '../../assets/no_photo.jpg';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Pagination } from '@zendeskgarden/react-pagination';
import { FlexSpacer } from '../../components/Elements/SmallElements';
import { Button, BtnIcon, IconButton } from '../../components/Elements/Button';
import CarDialog from '../../components/DataDialogs/CarDialog';
import { MdEdit, MdDelete } from 'react-icons/md';
import YesNoDialog from '../../components/Elements/YesNoDialog';
import debounce from 'debounce';
import PieTextField from '../../components/Elements/TextFields';
import { FaCheck } from "react-icons/fa";
import { FaSquarePlus, FaXmark } from "react-icons/fa6";

function AdminCars() {

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [selectedUserId, setSelectedUserId] = useState(query.has('user_id') ? query.get('user_id') : null);
  const [searchText, setSearchText] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const [carsResponse, setCarsResponse] = useState(null);
  const [page, setPage] = useState(1);

  const [isCarDialogOpen, setCarDialogOpen] = useState(false);
  const [isDeleteCarDialogOpen, setDeleteCarDialogOpen] = useState(false);
  const [carObj, setCarObj] = useState(null);

  const { t } = useTranslation();

  const accessToken = useSelector(state => state.accessToken);
  const isRTL = useSelector(state => state.isRTL);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const setPageTitle = () => {
    document.title = t('cars') + ' | ' + t('app_name');
  }

  const getQuery = () => {
    let query = '?page=' + page;

    if (searchText !== null && searchText.length > 0) {
      query += '&search_query=' + encodeURI(searchText);
    }

    if (selectedUserId !== null) {
      query += '&user_id=' + selectedUserId;
    }

    return query;
  };

  const getCars = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/admin/cars/' + getQuery(), {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setCarsResponse(response.data);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    setPageTitle();
  }, []);

  useEffect(() => {
    getCars();
  }, [page, selectedUserId, searchText]);

  useEffect(() => {
    setPage(1);
  }, [selectedUserId, searchText]);

  const onPageClick = (value) => {
    setPage(value);
    window.scroll(0, 0);
  };

  const addCar = () => {
    setCarObj(null);
    setCarDialogOpen(true);
  };

  const editCar = (e, car) => {
    e.stopPropagation();
    setCarObj(Object.assign({}, car));
    setCarDialogOpen(true);
  };

  const deleteCar = (e, car) => {
    e.stopPropagation();
    setCarObj(Object.assign({}, car));
    setDeleteCarDialogOpen(true);
  };

  const confirmCarDeletion = () => {
    dispatch(setLoadingDialog(true));
    axios.delete('/admin/cars/' + carObj.id + '/', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      setCarObj(null);
      getCars();
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
    });
  };

  const debouncedSearch = useCallback(debounce(setSearchText, 500), []);

  const onSearchChange = (e) => {
    setSearchInput(e.target.value);
    debouncedSearch(e.target.value);
  };

  return (
    <>
      <CarDialog
        isOpen={isCarDialogOpen}
        setOpen={setCarDialogOpen}
        carObj={carObj}
        onSuccess={getCars}
      />
      <YesNoDialog
        isOpen={isDeleteCarDialogOpen}
        setOpen={setDeleteCarDialogOpen}
        title={t('delete_car')}
        text={t('confirm_delete_car')}
        onYesClicked={confirmCarDeletion}
      />
      <AdminPageDiv>
        <Container fluid>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
            <Text fontSize="25px" fontWeight="700">
              {t('cars')}
            </Text>
            <FlexSpacer isRTL={isRTL} />
            <Button color="var(--secondary-color)" dark padding="10px 20px" borderRadius={10}
              onClick={addCar}>
              <BtnIcon left={!isRTL} right={isRTL} color="white" iconSize={'26px'}>
                <FaSquarePlus />
              </BtnIcon>
              {t('add_car')}
            </Button>
          </div>
          <div style={{marginTop: '20px'}}>
            <PieTextField
              type='text'
              placeholder={t('search_placeholder')}
              outlined
              value={searchInput}
              background='transparent'
              onChange={(e) => onSearchChange(e)}
            />
          </div>
          {
            carsResponse !== null && carsResponse.data.length > 0 ? <>
              <Table style={{marginTop: '20px'}}>
                <thead>
                  <TH>
                    {t('image')}
                  </TH>
                  <TH>
                    {t('user')}
                  </TH>
                  <TH>
                    {t('date')}
                  </TH>
                  <TH>
                    {t('year_make_model')}
                  </TH>
                  <TH>
                    {t('vin')}
                  </TH>
                  <TH>
                    {t('color')}
                  </TH>
                  <TH>
                    {t('keys')}
                  </TH>
                  <TH>
                    {t('branch')}
                  </TH>
                  <TH>
                    {t('is_shipped')}
                  </TH>
                  <TH>
                    {t('actions')}
                  </TH>
                </thead>
                <TBody>
                  {
                    carsResponse.data.map((car) => {
                      return (
                        <TR key={'car__' + car.id} clickable onClick={() => navigate('/cars/' + car.id)}>
                          <TD>
                            <Image
                              src={car.first_image !== null ? car.first_image.thumb : no_photo}
                              height='110px'
                            />
                          </TD>
                          <TD>
                            {car.user.name}
                          </TD>
                          <TD>
                            {car.buying_date}
                          </TD>
                          <TD>
                            {car.year_make_model}
                          </TD>
                          <TD>
                            {car.vin}
                          </TD>
                          <TD>
                            {car.color}
                          </TD>
                          <TD>
                            {car.key_info}
                          </TD>
                          <TD>
                            {car.branch}
                          </TD>
                          <TD fontSize='24px' color='var(--primary-color)'>
                            {car.is_shipped === 1 ? <FaCheck /> : <FaXmark />}
                          </TD>
                          <TD>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <IconButton iconSize="23px" color="var(--primary-color)"
                                onClick={(e) => editCar(e, car)}>
                                <MdEdit />
                              </IconButton>
                              <IconButton iconSize="23px" color="var(--danger-color)"
                                style={{margin: '0px 5px'}}
                                onClick={(e) => deleteCar(e, car)}>
                                <MdDelete />
                              </IconButton> 
                            </div>
                          </TD>
                        </TR>
                      );
                    })
                  }
                </TBody>
              </Table>
            </> : <></>
          }
          {
            carsResponse !== null ?
            <div style={{marginTop: '10px'}}>
              {
                carsResponse.last_page > 1 ?
                <div style={{marginTop: '10px'}}>
                  <ThemeProvider>
                    <Pagination
                      totalPages={carsResponse.last_page}
                      currentPage={page}
                      onChange={onPageClick}
                    />
                  </ThemeProvider>
                </div> : <></>
              }
            </div> : <></>
          }
        </Container>
      </AdminPageDiv>
    </>
  )
}

export default AdminCars;
