import React, { useEffect, useState } from 'react';
import {
  AccountMainContainer,
  AccountContainer,
  AccountDiv,
  AccountHeader,
  InfoContainer,
  FormTitle,
  AccountTextField
} from './OuterViewElements';
import roboa_alqalaa_logo from '../assets/roboa_alqalaa_logo.png';
import { Button } from '../components/Elements/Button';
import { useSelector, useDispatch } from 'react-redux';
import { setLoadingDialog, setError, setUser } from '../store';
import { useCookies } from 'react-cookie';
import constants from '../utils/constants';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios, { API_KEY_HEADER } from '../utils/axios';
import LanguageSelector from '../components/LanguageSelector';

function Login() {

  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');

  const lang = useSelector(state => state.lang);
  const isRTL = useSelector(state => state.isRTL);

  const [cookies, setCookie] = useCookies();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isFormValid = () => {
    let isValid = phoneNumber.length > 0 && password.length > 0;
    return isValid;
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      loginUser();
    }
  }

  const setTitle = () => {
    document.title = t('login_title') + ' | ' + t('app_name');
  }

  const loginUser = () => {
    if (!isFormValid()) {
      return;
    }
    let headers = API_KEY_HEADER;
    headers.headers.lang = lang;
    dispatch(setLoadingDialog(true));
    axios.post('/auth/login/', {phone_number: phoneNumber, password: password, session_type: 0}, headers).then((response) => {
      dispatch(setLoadingDialog(false));
      setCookie(constants.cookies.TOKEN, response.data.token, { path: '/', domain: constants.COOKIES_DOMAIN_NAME });
      window.localStorage.setItem(constants.REFRESH_TOKEN_LOCALSTORAGE, response.data.refresh_token);
      // dispatch(setUser(response.data.user));
      window.location.href = '/';
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        dispatch(setError(true, t('unable_to_login')));
      } else {
        dispatch(setError(true, t('general_error')));
      }
      setTimeout(() => {
        dispatch(setError(false, ''));
      }, 3000);
    });
  }

  useEffect(() => {
    setTitle();
  }, []);

  return (
    <>
      <AccountMainContainer>
        <AccountContainer>
          <AccountHeader>
            <Link to="/" style={{textDecoration: 'none'}}>
              <img
                src={roboa_alqalaa_logo}
                height={100}
                alt="RoboaAlqalaa"
                style={{userSelect: 'none', pointerEvents: 'none'}}
              />
            </Link>
            <div style={{marginLeft: !isRTL ? 'auto' : '0', marginRight: isRTL ? 'auto' : '0'}}>
              <LanguageSelector />
            </div>
          </AccountHeader>
          <AccountDiv>
            <InfoContainer>
              <FormTitle style={{textAlign: 'center'}}>
                {t('login_title')}
              </FormTitle>
              <AccountTextField placeholder={t('phone_number')} style={{marginTop: '30px'}} type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <AccountTextField placeholder={t('password')} style={{marginTop: '10px'}} type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <Button color="var(--primary-color)" padding="14px" fontSize="18px" borderRadius={20}
                block
                dark
                disabled={!isFormValid()}
                onClick={() => loginUser()}>
                {t('login_btn')}
              </Button>
            </InfoContainer>
          </AccountDiv>
        </AccountContainer>
      </AccountMainContainer>
    </>
  );
}

export default Login;