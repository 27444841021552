const SERVER_DNS = process.env.REACT_APP_PROTOCOL + '://' + process.env.REACT_APP_DNS;

const constants = {
  BASE_URL: SERVER_DNS + '/api',
  API_KEY: process.env.REACT_APP_API_KEY,
  REFRESH_TOKEN_LOCALSTORAGE: 'ROBOAA_REF_TOK',
  SUPPORTED_LANGS: ['en', 'ar'],
  COOKIES_DOMAIN_NAME: 'www.roboaalqalaa.com',
  cookies: {
    LANG: '_ROBOA_LOCALE',
    TOKEN: '_ROBOA_TOK',
  },
  status: {
    INTERNAL_SERVER_ERROR: 500,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    BAD_REQUEST: 400
  }
};

export default constants;
