import React, { useState, useEffect } from 'react';
import { AdminPageDiv, Container } from '../../layouts/Containers';
import { Text } from '../../components/Elements/Typography';
import { useTranslation } from 'react-i18next';
import axios from '../../utils/axios';
import constants from '../../utils/constants';
import { setLoadingDialog } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Pagination } from '@zendeskgarden/react-pagination';
import {
  List,
  ListItem,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemEnd
} from '../../components/Elements/List';
import { Avatar, AvatarImg, FlexSpacer } from '../../components/Elements/SmallElements';
import { Button, BtnIcon, IconButton } from '../../components/Elements/Button';
import { FaSquarePlus } from "react-icons/fa6";
import { MdEdit } from 'react-icons/md';
import UserDialog from '../../components/DataDialogs/UserDialog';
import { useNavigate } from 'react-router-dom';
import { IoMdCar } from "react-icons/io";

function Users() {

  const [usersResponse, setUsersResponse] = useState(null);
  const [page, setPage] = useState(1);

  const [isUserDialogOpen, setUserDialogOpen] = useState(false);
  const [userObj, setUserObj] = useState(null);

  const { t } = useTranslation();

  const accessToken = useSelector(state => state.accessToken);
  const isRTL = useSelector(state => state.isRTL);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const setPageTitle = () => {
    document.title = t('users') + ' | ' + t('app_name');
  }

  const getUsers = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/admin/users/?page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setUsersResponse(response.data);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    setPageTitle();
  }, []);

  useEffect(() => {
    getUsers();
  }, [page]);

  const onPageClick = (value) => {
    setPage(value);
    window.scroll(0, 0);
  };

  const addUser = () => {
    setUserObj(null);
    setUserDialogOpen(true);
  };

  const editUser = (user) => {
    setUserObj(Object.assign({}, user));
    setUserDialogOpen(true);
  };

  return (
    <>
      <UserDialog
        isOpen={isUserDialogOpen}
        setOpen={setUserDialogOpen}
        userObj={userObj}
        onSuccess={getUsers}
      />
      <AdminPageDiv>
        <Container fluid>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
            <Text fontSize="25px" fontWeight="700">
              {t('users')}
            </Text>
            <FlexSpacer isRTL={isRTL} />
            <Button color="var(--secondary-color)" dark padding="10px 20px" borderRadius={10}
              onClick={addUser}>
              <BtnIcon left={!isRTL} right={isRTL} color="white" iconSize={'26px'}>
                <FaSquarePlus />
              </BtnIcon>
              {t('add_user')}
            </Button>
          </div>
          {
            usersResponse !== null ? 
            <List style={{marginTop: '20px'}}>
              {
                usersResponse.data.map((user) => {
                  return (
                    <ListItem borderRadius="10px" key={'user__' + user.id}>
                      {
                        user.user !== null ?
                        <Avatar size={85}>
                          {
                            user.image_url === null ? user.name.substring(0, 1) : <AvatarImg src={user.image_url} />
                          }
                        </Avatar> : <></>
                      }
                      <ListItemContent>
                        <ListItemTitle fontSize="15px">
                          {user.name}
                        </ListItemTitle>
                        <ListItemSubtitle fontSize="14px">
                          {user.phone_number}
                        </ListItemSubtitle>
                      </ListItemContent>
                      <ListItemEnd isRTL={isRTL}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <IconButton iconSize='27px' color='var(--primary-color)'
                            style={{margin: '0px 5px'}}
                            onClick={() => navigate('/?user_id=' + user.id)}>
                            <IoMdCar />
                          </IconButton>
                          <IconButton iconSize='27px' color='var(--primary-color)'
                            onClick={() => editUser(user)}>
                            <MdEdit />
                          </IconButton>
                        </div>
                      </ListItemEnd>
                    </ListItem>
                  );
                })
              }
            </List> : <></>
          }
          {
            usersResponse !== null ?
            <div style={{marginTop: '10px'}}>
              {
                usersResponse.last_page > 1 ?
                <div style={{marginTop: '10px'}}>
                  <ThemeProvider>
                    <Pagination
                      totalPages={usersResponse.last_page}
                      currentPage={page}
                      onChange={onPageClick}
                    />
                  </ThemeProvider>
                </div> : <></>
              }
            </div> : <></>
          }
        </Container>
      </AdminPageDiv>
    </>
  )
}

export default Users;
