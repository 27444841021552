import styled from 'styled-components';

export const AccountMainContainer = styled.div`
  width: 100%;
  background: #fff;
`

export const AccountContainer = styled.div`
  min-height: 100vh;
  padding: 15px;
  max-width: var(--max-login-width);
  margin-left: auto;
  margin-right: auto;
`

export const AccountHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

export const AccountDiv = styled.div`
  width: 100%;
  max-width: 500px;
  min-height: 70vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InfoContainer = styled.div`
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background: var(--third-color);
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`

export const FormTitle = styled.div`
  font-size: 40px;
  text-align: center;
  font-weight: 300;
`

export const AccountTextField = styled.input`
  background: #fff;
  width: 100%;
  padding: 16px 20px;
  color: #000;
  font-size: 18px;
  margin-bottom: 20px;
  border-radius: 10px;
  font-family: inherit;
  transition: 0.1s ease;
  resize: none;
  outline: none;
  border: none;

  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 500;
    user-select: none;
  }
`
