import React, { useState, useEffect } from 'react';
import Dialog from '../Elements/Dialog';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getBadRequestErrorMessage } from '../../utils/helpers';
import { Button } from '../Elements/Button';
import axios from '../../utils/axios';
import { setLoadingDialog, setSuccess, setError } from '../../store';
import constants from '../../utils/constants';
import { FlexSpacer } from '../Elements/SmallElements';
import PieTextField from '../Elements/TextFields';
import PieSelect from '../Elements/Selects';
import PieCheckbox from '../Elements/Checkbox';

function CarDialog({ isOpen, setOpen, carObj=null, onSuccess }) {

  const { t } = useTranslation();

  const [selectedUser, setSelectedUser] = useState(null);
  const [yearMakeModel, setYearMakeModel] = useState('');
  const [buyingDate, setBuyingDate] = useState('');
  const [isSetDeliveryDate, setSetDeliveryDate] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [vin, setVin] = useState('');
  const [color, setColor] = useState('');
  const [keyInfo, setKeyInfo] = useState('');
  const [branch, setBranch] = useState('');
  const [carValue, setCarValue] = useState('');
  const [containerNumber, setContainerNumber] = useState('');
  const [shippingPrice, setShippingPrice] = useState('');
  const [trackingUrl, setTrackingUrl] = useState('');
  const [isShipped, setShipped] = useState(false);

  const [users, setUsers] = useState([]);

  const accessToken = useSelector(state => state.accessToken);
  const lang = useSelector(state => state.lang);
  const isRTL = useSelector(state => state.isRTL);

  const dispatch = useDispatch();

  const clearData = () => {
    setSelectedUser(null);
    setYearMakeModel('');
    setBuyingDate('');
    setSetDeliveryDate(false);
    setDeliveryDate('');
    setVin('');
    setColor('');
    setKeyInfo('');
    setBranch('');
    setCarValue('');
    setContainerNumber('');
    setShippingPrice('');
    setTrackingUrl('');
    setShipped(false);
  };

  const setDialogOpen = (open) => {
    if (!open) {
      clearData();
    }
    setOpen(open);
  };

  const isFormValid = () => {
    let isValid = yearMakeModel.length > 0 && buyingDate.length > 0 && vin.length > 0 &&
      color.length > 0 && keyInfo.length > 0 && branch.length > 0 && carValue.length > 0 && containerNumber.length > 0 &&
      shippingPrice.length > 0 && trackingUrl.length > 0;
    
    if (carObj === null) {
      isValid = isValid && selectedUser !== null;
    }

    return isValid;
  };

  const getUsers = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/admin/express_users/', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
        'lang': lang,
      }
    }).then((response) => {
      setUsers(response.data);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const addCar = () => {
    if (!isFormValid()) {
      return;
    }

    let data = {
      user_id: selectedUser,
      year_make_model: yearMakeModel,
      buying_date: buyingDate,
      delivery_date: isSetDeliveryDate ? deliveryDate : null,
      vin: vin,
      color: color,
      key_info: keyInfo,
      branch: branch,
      car_value: carValue,
      container_number: containerNumber,
      shipping_price: shippingPrice,
      tracking_url: trackingUrl,
      is_shipped: isShipped,
    };

    dispatch(setLoadingDialog(true));
    axios.post('/admin/cars/', data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      dispatch(setSuccess(true, t('general_success')));
      setTimeout(() => {
        dispatch(setSuccess(false, ''));
      }, 3000);
      setDialogOpen(false);
      if (onSuccess !== null) {
        onSuccess();
      }
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.BAD_REQUEST) {
        dispatch(setError(true, t(getBadRequestErrorMessage(error.response.data))));
      } else {
        dispatch(setError(true, t('general_error')));
      }
      setTimeout(() => {
        dispatch(setError(false, ''));
      }, 3000);
    });
  };

  const editCar = () => {
    if (!isFormValid()) {
      return;
    }

    let data = {
      year_make_model: yearMakeModel,
      buying_date: buyingDate,
      delivery_date: isSetDeliveryDate ? deliveryDate : null,
      vin: vin,
      color: color,
      key_info: keyInfo,
      branch: branch,
      car_value: carValue,
      container_number: containerNumber,
      shipping_price: shippingPrice,
      tracking_url: trackingUrl,
      is_shipped: isShipped,
    };

    dispatch(setLoadingDialog(true));
    axios.put('/admin/cars/' + carObj.id + '/', data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      dispatch(setSuccess(true, t('general_success')));
      setTimeout(() => {
        dispatch(setSuccess(false, ''));
      }, 3000);
      setDialogOpen(false);
      if (onSuccess !== null) {
        onSuccess();
      }
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.BAD_REQUEST) {
        dispatch(setError(true, t(getBadRequestErrorMessage(error.response.data))));
      } else {
        dispatch(setError(true, t('general_error')));
      }
      setTimeout(() => {
        dispatch(setError(false, ''));
      }, 3000);
    });
  };

  const onConfirmClick = () => {
    if (carObj === null) {
      addCar();
    } else {
      editCar();
    }
  };

  useEffect(() => {
    if (carObj !== null) {
      setYearMakeModel(carObj.year_make_model);
      setBuyingDate(carObj.buying_date);
      setSetDeliveryDate(carObj.delivery_date !== null);
      setDeliveryDate(carObj.delivery_date);
      setVin(carObj.vin);
      setColor(carObj.color);
      setKeyInfo(carObj.key_info);
      setBranch(carObj.branch);
      setCarValue(carObj.car_value);
      setContainerNumber(carObj.container_number);
      setShippingPrice(carObj.shipping_price);
      setTrackingUrl(carObj.tracking_url !== null ? carObj.tracking_url : '');
      setShipped(carObj.is_shipped === 1);
    } else {
      clearData();
    }
  }, [carObj]);

  return (
    <>
      <Dialog
        isOpen={isOpen}
        setOpen={setDialogOpen}
        maxWidth="500px"
        scrollable
        title={carObj === null ? t('add_car') : t('edit_car')}
        padding='10px 15px'
        child={
          <>
            <div>
              <PieTextField
                type="text"
                label={t('year_make_model')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={yearMakeModel}
                onChange={(e) => setYearMakeModel(e.target.value)}
              />
            </div>
            {
              carObj === null ? 
              <div style={{marginTop: '10px'}}>
                <PieSelect
                  label={t('user')}
                  labelFontWeight='500'
                  verticalLabel
                  outlined
                  items={users}
                  value={selectedUser}
                  itemValue='id'
                  itemText='name'
                  onChange={(value) => setSelectedUser(value)}
                />
              </div> : <></>
            }
            <div style={{marginTop: '10px'}}>
              <PieTextField
                type="date"
                label={t('buying_date')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={buyingDate}
                onChange={(e) => setBuyingDate(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <PieCheckbox
                title={t('set_delivery_date')}
                value={isSetDeliveryDate}
                onChange={(value) => setSetDeliveryDate(value)}
              />
            </div>
            {
              isSetDeliveryDate ? 
              <div style={{marginTop: '10px'}}>
                <PieTextField
                  type="date"
                  label={t('delivery_date')}
                  labelFontWeight='500'
                  verticalLabel
                  outlined
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                />
              </div> : <></>
            }
            <div style={{marginTop: '10px'}}>
              <PieTextField
                type="text"
                label={t('vin')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={vin}
                onChange={(e) => setVin(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <PieTextField
                type="text"
                label={t('color')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <PieTextField
                type="text"
                label={t('keys')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={keyInfo}
                onChange={(e) => setKeyInfo(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <PieTextField
                type="text"
                label={t('branch')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <PieTextField
                type="number"
                label={t('value')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={carValue}
                onChange={(e) => setCarValue(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <PieTextField
                type="text"
                label={t('container_number')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={containerNumber}
                onChange={(e) => setContainerNumber(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <PieTextField
                type="number"
                label={t('shipping_price')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={shippingPrice}
                onChange={(e) => setShippingPrice(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <PieTextField
                type="text"
                label={t('tracking_url')}
                labelFontWeight='500'
                verticalLabel
                outlined
                value={trackingUrl}
                onChange={(e) => setTrackingUrl(e.target.value)}
              />
            </div>
            <div style={{marginTop: '10px'}}>
              <PieCheckbox
                title={t('is_shipped')}
                value={isShipped}
                onChange={(value) => setShipped(value)}
              />
            </div>
          </>
        }
        actions={
          <>
            <FlexSpacer isRTL={isRTL} />
            <Button borderRadius={7} padding="10px 15px" dark disabled={!isFormValid()}
              onClick={onConfirmClick}>
              {t('confirm')}
            </Button>
          </>
        }
      />
    </>
  );
}

export default CarDialog;