import styled from 'styled-components';
import breakpoint from '../../utils/breakpoint';

export const FooterContainer = styled.footer`
  background-color: var(--primary-color);
`

export const FooterWrap = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: var(--max-page-width);
  margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (${breakpoint.devices_max.sm}) {
    padding-top: 32px;
  }
`

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (${breakpoint.devices_max.sm}) {
    flex-direction: column;
  }
`

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  text-align: ${props => props.isRTL ? 'right' : 'left'};
  box-sizing: border-box;
  padding: 0px 60px;
  color: #fff;

  @media screen and (${breakpoint.devices_max.xs}) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px; 
`

export const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #FAFAFA;
    font-weight: bold;
    transition: 0.1s ease-out;
  }
`

export const FooterSocialMedia = styled.section`
  width: 100%;
  max-width: var(--max-page-width);
`

export const FooterSocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto 0 auto;

  @media screen and (${breakpoint.devices_max.sm}) {
    flex-direction: column;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const SocialLogo = styled.a`
  text-decoration: none;
  justify-self: start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.2s ease-out;
  }
`

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`
