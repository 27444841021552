import styled from 'styled-components';
import breakpoint from '../../utils/breakpoint';

export const AppBarContainer = styled.div`
  background: ${props => props.isTransparent ? 'transparent' : '#fff'};
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 50;
`

export const AppBarDiv = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  z-index: 1;
  width: 100%;
  padding: 0px 20px;
`

export const MobileMenuIcon = styled.div`
  display: none;

  @media screen and (${breakpoint.devices_max.sm}) {
    display: block;
    color: #000;
    font-size: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: ${props => props.isRTL ? '0px' : '15px'};
    margin-left: ${props => props.isRTL ? '15px' : '0px'};
  }
`

export const AppBarEnd = styled.div`
  margin-left: ${props => props.isRTL ? '0' : 'auto'};
  margin-right: ${props => props.isRTL ? 'auto' : '0'};
`
