import React, { useState, useEffect, useRef } from 'react';
import { AdminPageDiv, Container } from '../../layouts/Containers';
import { Text } from '../../components/Elements/Typography';
import { useTranslation } from 'react-i18next';
import axios from '../../utils/axios';
import constants from '../../utils/constants';
import { setLoadingDialog } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import no_photo from '../../assets/no_photo.jpg';
import { FlexSpacer } from '../../components/Elements/SmallElements';
import { IconButton } from '../../components/Elements/Button';
import { MdImage } from 'react-icons/md';
import { Image } from '../../components/Elements/Image';

function Settings() {

  const [settings, setSettings] = useState(null);

  const { t } = useTranslation();

  const accessToken = useSelector(state => state.accessToken);
  const isRTL = useSelector(state => state.isRTL);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const imgInput = useRef();

  const setPageTitle = () => {
    document.title = t('settings') + ' | ' + t('app_name');
  }

  const getSettings = () => {
    dispatch(setLoadingDialog(true));
    axios.get('/admin/configs/home_page_jumbo_image/', {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      setSettings(response.data);
      dispatch(setLoadingDialog(false));
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    setPageTitle();
    getSettings();
  }, []);

  const updateHomePageImage = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    let file = e.target.files[0];

    let data = new FormData();
    data.set('image', file);

    dispatch(setLoadingDialog(true));
    axios.post('/admin/configs/home_page_jumbo_image/', data, {
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      }
    }).then((response) => {
      dispatch(setLoadingDialog(false));
      getSettings();
    }).catch((error) => {
      dispatch(setLoadingDialog(false));
    });
  };

  return (
    <>
      <input 
        type="file"
        ref={imgInput}
        style={{display: 'none'}}
        accept="image/jpeg,image/jpg,image/png"
        onChange={updateHomePageImage}
      />
      <AdminPageDiv>
        <Container fluid>
          <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
            <Text fontSize="25px" fontWeight="700">
              {t('home_page_jumbo_image')}
            </Text>
            <FlexSpacer isRTL={isRTL} />
            <IconButton iconSize="29px" color="rgba(0, 0, 0, 0.85)" onClick={() => imgInput.current.click()}>
              <MdImage />
            </IconButton>
          </div>
          <Image
            style={{marginTop: '15px'}}
            src={settings !== null && settings.home_page_jumbo_image !== null ? settings.home_page_jumbo_image : no_photo}
            height={500}
          />
        </Container>
      </AdminPageDiv>
    </>
  )
}

export default Settings;
