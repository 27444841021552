import React from 'react';
import {
  UserBarContainer,
  UserBarDiv,
  BarLogo,
  UserBarEnd
} from './UserBarElements';
import roboa_alqalaa_logo from '../../assets/roboa_alqalaa_logo.png';
import { useSelector } from 'react-redux';
import UserMenu from '../../components/UserMenu';
import LanguageSelector from '../../components/LanguageSelector';
import { Button } from '../../components/Elements/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function UserBar() {

  const { t } = useTranslation();

  const accessToken = useSelector(state => state.accessToken);
  const isRTL = useSelector(state => state.isRTL);

  const navigate = useNavigate();

  return (
    <>
      <UserBarContainer>
        <UserBarDiv>
          <BarLogo to='/'>
            <img
              src={roboa_alqalaa_logo}
              height={60}
              alt="Tawlat"
              style={{userSelect: 'none', pointerEvents: 'none'}}
            />
          </BarLogo>
          <UserBarEnd isRTL={isRTL}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{marginRight: isRTL ? '0px' : '10px', marginLeft: isRTL ? '10px' : '0px'}}>
                <LanguageSelector />
              </div>
              {
                accessToken !== null ? <UserMenu /> : 
                <Button color="var(--primary-color)" dark padding="10px 20px" borderRadius={10}
                  onClick={() => navigate('/login')}>
                  {t('login_btn')}
                </Button>
              }
            </div>
          </UserBarEnd>
        </UserBarDiv>
      </UserBarContainer>
    </>
  )
}

export default UserBar;