import React, { useState } from 'react';
import {
  NavBar,
  NavBarContainer,
  MobileNavOverlay,
  NavBarLogoContainer
} from './AppNavigationElements';
import {
  List,
  ListItem,
  ListItemRR,
  ListItemIcon,
  ListItemContent,
  ListItemTitle,
  ListItemEnd,
} from '../../components/Elements/List';
import { useSelector } from 'react-redux';
import { FaUsers } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import roboa_alqalaa_logo from '../../assets/roboa_alqalaa_logo.png';
import { useNavigate } from 'react-router-dom';
import { IoMdCar } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";


function AdminAppBar({ isMobileBarOpen, setMobileBarOpen }) {

  const [isEmployeeViewsOpen, setEmployeeViewsOpen] = useState(false);

  const user = useSelector(state => state.user);
  const isRTL = useSelector(state => state.isRTL);

  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <>
      <MobileNavOverlay isOpen={isMobileBarOpen} onClick={() => setMobileBarOpen(false)}></MobileNavOverlay>
      <NavBar isOpen={isMobileBarOpen} isRTL={isRTL}>
        <NavBarContainer>
          <NavBarLogoContainer to='/'>
            <img 
              src={roboa_alqalaa_logo}
              height="70"
              alt="RoboaAlQalaa"
              style={{pointerEvents: 'none', userSelect: 'none'}}
            />
          </NavBarLogoContainer>
          <List style={{marginTop: '15px'}}>
            <ListItemRR to={'/'} onClick={() => setMobileBarOpen(false)}>
              <ListItemIcon>
                <IoMdCar />
              </ListItemIcon>
              <ListItemContent>
                <ListItemTitle fontWeight="500">
                  {t('cars')}
                </ListItemTitle>
              </ListItemContent>
            </ListItemRR>
            <ListItemRR to={'/users'} onClick={() => setMobileBarOpen(false)} end>
              <ListItemIcon>
                <FaUsers />
              </ListItemIcon>
              <ListItemContent>
                <ListItemTitle fontWeight="500">
                  {t('users')}
                </ListItemTitle>
              </ListItemContent>
            </ListItemRR>
            <ListItemRR to={'/settings'} onClick={() => setMobileBarOpen(false)} end>
              <ListItemIcon>
                <IoMdSettings />
              </ListItemIcon>
              <ListItemContent>
                <ListItemTitle fontWeight="500">
                  {t('settings')}
                </ListItemTitle>
              </ListItemContent>
            </ListItemRR>
          </List>
        </NavBarContainer>
      </NavBar>
    </>
  );
}

export default AdminAppBar;