import React from 'react';
import { openInNewTab } from '../../utils/helpers';
import constants from '../../utils/constants';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterSocialMedia,
  FooterSocialMediaWrap,
  LogoContainer,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './AppFooterElements';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import roboa_alqalaa_logo from '../../assets/roboa_alqalaa_logo.png';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Image } from '../../components/Elements/Image';
import { useNavigate } from 'react-router-dom';


const Footer = () => {

  const navigate = useNavigate();

  const { t } = useTranslation();
  const isRTL = useSelector(state => state.isRTL);

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems isRTL={isRTL}>
              <FooterLinkTitle>
                {t('links').toUpperCase()}
              </FooterLinkTitle>
              <FooterLink onClick={() => navigate('/login')}>
                {t('login_btn')}
              </FooterLink>
              <FooterLink onClick={() => openInNewTab('https://www.facebook.com/ROBOAAALQALAAH')}>
                {t('facebook')}
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems isRTL={isRTL}>
              <FooterLinkTitle>
                {t('contact').toUpperCase()}
              </FooterLinkTitle>
              <div style={{color: 'white', direction: 'ltr', fontSize: '14px'}}>
                0793636700
              </div>
              <div style={{color: 'white', direction: 'ltr', fontSize: '14px', marginTop: '7px'}}>
                0793636800
              </div>
              <div style={{color: 'white', direction: 'ltr', fontSize: '14px', marginTop: '7px'}}>
                0793636900
              </div>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <FooterSocialMedia>
          <FooterSocialMediaWrap>
            <LogoContainer>
              <SocialLogo onClick={() => navigate('/')}>
                <Image 
                  src={roboa_alqalaa_logo}
                  height={70}
                  style={{objectFit: 'contain'}}
                  alt="RoboaAlQalaa"
                />
              </SocialLogo>
            </LogoContainer>
            <div>
              {/* <SocialIcons style={{marginTop: '10px'}}>
                <SocialIconLink
                  onClick={() => openInNewTab('https://www.facebook.com/ROBOAAALQALAAH')}
                  aria-label="Facebook">
                    <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  onClick={() => goToLink(constants.links.INSTAGRAM, true)}
                  aria-label="Instagram">
                    <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  onClick={() => goToLink(constants.links.WHATSAPP_LINK, true)}
                  aria-label="Whatsapp">
                    <FaWhatsapp />
                </SocialIconLink>
                <SocialIconLink
                  onClick={() => goToLink(constants.links.LINKEDIN, true)}
                  aria-label="LinkedIn">
                    <FaLinkedin />
                </SocialIconLink>
                <SocialIconLink
                  onClick={() => goToLink(constants.links.TWITTER, true)}
                  aria-label="Twitter">
                    <FaTwitter />
                </SocialIconLink>
              </SocialIcons> */}
            </div>
          </FooterSocialMediaWrap>
        </FooterSocialMedia>
        <WebsiteRights style={{textAlign: 'center'}}>
          All rights reserved. <span style={{fontWeight: 'bold'}}>Roboa Alqalaa</span> &copy; {new Date().getFullYear()}
        </WebsiteRights>
      </FooterWrap>
    </FooterContainer>
  );
}

export default Footer;
