import constants from './constants';
import moment from 'moment';
import 'moment/locale/ar';
import axios, { API_KEY_HEADER } from './axios';
import { setLoadingDialog } from '../store';


export function openInNewTab(url) {
  var win = window.open(url, '_blank')
  win.focus()
}

export const getLangName = (lang) => {
  if (lang === 'en') {
    return 'English';
  } else if (lang === 'ar') {
    return 'العربية';
  }
  return '';
};

export const logoutUserLocally = (removeCookie) => {
  removeCookie(constants.cookies.TOKEN, { path: '/', domain: constants.COOKIES_DOMAIN_NAME });
  window.localStorage.removeItem(constants.REFRESH_TOKEN_LOCALSTORAGE);
  window.location.reload();
};

export const logoutUser = (accessToken, dispatch, removeCookie) => {
  dispatch(setLoadingDialog(true));
  axios.post('/auth/logout/', {token: accessToken}, API_KEY_HEADER)
    .then((response) => {
      dispatch(setLoadingDialog(false));
      logoutUserLocally(removeCookie);
    })
    .catch((error) => {
      dispatch(setLoadingDialog(false));
      if (error.response.status === constants.status.UNAUTHORIZED) {
        logoutUserLocally(removeCookie);
      }
    });
}

export const refreshToken = (setCookie, removeCookie) => {
  axios.post('/auth/refresh/', {
    refresh_token: window.localStorage.getItem(constants.REFRESH_TOKEN_LOCALSTORAGE),
  }, API_KEY_HEADER).then((response) => {
    const data = response.data;
    setCookie(constants.cookies.TOKEN, data.token, { path: '/', domain: constants.COOKIES_DOMAIN_NAME });
    if (data.refresh_token !== null) {
      window.localStorage.setItem(constants.REFRESH_TOKEN_LOCALSTORAGE, data.refresh_token);
    }
    window.location.reload();
  }).catch((error) => {
    if (error.response.status === constants.status.UNAUTHORIZED) {
      logoutUserLocally(removeCookie);
    }
  });
};

export const getBadRequestErrorMessage = (data) => {

  if (data === null || data.message === undefined) {
    return 'general_error';
  }

  if (data.message === 'User_Exists') {
    return 'user_exists_on_rest';
  }

  return 'general_error';
};

export const formatDateTime = (date_str, lang = 'en', format = 'LLLL') => {
  moment.locale(lang);
  let date = moment(date_str);
  return date.format(format);
};

export const localDateTimeToUtc = (time) => {
  moment.locale('en');
  let date = new moment(time, 'YYYY-MM-DDTHH:mm').utc();
  return date.format('YYYY-MM-DDTHH:mm');
};

export const utcDateTimeToLocal = (time) => {
  moment.locale('en');
  let date = moment.utc(time, 'YYYY-MM-DDTHH:mm').local();
  return date.format('YYYY-MM-DDTHH:mm');
};

export const formatTime = (time, format, lang = 'en') => {
  moment.locale(lang);
  let date = moment(time, 'HH:mm:ss');
  return date.format(format);
};

export const formatDate = (date_str, format) => {
  moment.locale('en');
  let date = moment(date_str);
  return date.format(format);
};

export const localTimeToUtc = (time) => {
  moment.locale('en');
  let date = new moment(time, 'HH:mm:ss').utc();
  return date.format('HH:mm:ss');
};

export const utcToLocalTime = (time) => {
  moment.locale('en');
  let date = moment.utc(time, 'HH:mm:ss').local();
  return date.format('HH:mm:ss');
};

export const isLessThanNow = (date) => {
  let current_time = moment();
  let shown_at = moment(utcDateTimeToLocal(date));
  let dif = current_time.diff(shown_at);
  return dif > 0;
};
